/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@angular/cdk/overlay-prebuilt.css';
@import './app/shared/forms/components/image-upload/styles.scss';

@layer utilities {
  $border-color: #d0d5dd;

  // scrollbar style for Firefox
  * {
    scrollbar-width: thin;
    scrollbar-color: $border-color transparent;
  }

  // scrollbar style for others browsers
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: $border-color;
    border-radius: 6px;
  }

  // hide input type=number arrow
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Image rendering to pixalated */
  .pixelated {
    image-rendering: pixelated;
  }
}
